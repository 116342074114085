export enum AP_PAYMENT_STATUS {
  APPROVED = "Approved",
  NEED_APPROVAL = "Need Approval",
  REJECTED = "Rejected",
  SETTLED = "Settled",
  VOID = "Void",
  NEW = "New",
}

const AP_PAYMENT_TYPE = {
  UNIDENTIFIED: "Unidentified",
  APPLIED: "Applied",
} as const;

export type AP_PAYMENT_TYPE_ENUM =
  (typeof AP_PAYMENT_TYPE)[keyof typeof AP_PAYMENT_TYPE];
