


























































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { AP_PAYMENT_STATUS } from "@/models/enums/ap-payment.enum";
import { Messages } from "@/models/enums/messages.enum";
import { ApPaymentListResponseDto } from "@/models/interface/ap-payment";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataResponseCompanyBank } from "@/models/interface/master.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { apPaymentServices } from "@/services/apPayment.service";
import { contactServices } from "@/services/contact.service";
import { generalJournalServices } from "@/services/generaljournal.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { settingsServices } from "@/services/settings.service";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

interface LoadingComponent {
  loadingBranch: boolean;
  loadingFind: boolean;
  loadingSupplierName: boolean;
  loadingBankName: boolean;
  loadingStatus: boolean;
  loadingCurrency: boolean;
  loadingApPaymentNumber: boolean;
  loadingApPaymentType: boolean;
}

const ALLOW_EDIT: AP_PAYMENT_STATUS[] = [AP_PAYMENT_STATUS.NEED_APPROVAL];

@Component
export default class ListApPayments extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "ListApPayments" });
  page = 1;
  limit = 10;
  totalData = 0;
  params: RequestQueryParamsModel = {};
  loading: LoadingComponent = {
    loadingBranch: false,
    loadingFind: false,
    loadingSupplierName: false,
    loadingBankName: false,
    loadingStatus: false,
    loadingCurrency: false,
    loadingApPaymentNumber: false,
    loadingApPaymentType: false,
  };
  dataBranch: DataWarehouseBranch[] = [];
  dataApPaymentNumber: ApPaymentListResponseDto[] = [];
  dataSupplierName: ContactData[] = [];
  dataSource: ApPaymentListResponseDto[] = [];
  dataBankName: DataResponseCompanyBank[] = [];
  dataStatus: ResponseListMaster[] = [];
  dataApPaymentType: ResponseListMaster[] = [];
  dataCurrency: DataMasterCurrency[] = [];

  columnsTable = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchWarehouseName",
      key: "branchWarehouseName",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_ap_payment_number"),
      dataIndex: "documentNo",
      key: "documentNo",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_ap_payment_type"),
      dataIndex: "paymentType",
      key: "paymentType",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "supplierName",
      key: "supplierName",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_payment_date"),
      dataIndex: "paymentDate",
      key: "paymentDate",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "currency",
      key: "currency",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_payment_amount"),
      dataIndex: "totalPaymentAmount",
      key: "totalPaymentAmount",
      width: 170,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_bank_name"),
      dataIndex: "bankName",
      key: "bankName",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNo",
      key: "journalNo",
      width: 170,
      scopedSlots: { customRender: "clickColumn" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$root.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
    },
  ];

  formRules = {
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    apPaymentNumber: {
      label: "lbl_ap_payment_number",
      name: "apPaymentNumber",
      placeholder: "lbl_ap_payment_number_placeholder",
      decorator: [
        "apPaymentNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierName",
      placeholder: "lbl_supplier_name_placeholder",
      decorator: [
        "supplierName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    apPaymentDate: {
      label: "lbl_ap_payment_date",
      name: "apPaymentDate",
      placeholder: "lbl_ap_payment_date_placeholder",
      decorator: [
        "apPaymentDate",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    purchaseOrderNumber: {
      label: "lbl_purchase_order_number",
      name: "purchaseOrderNumber",
      placeholder: "lbl_purchase_order_number_placeholder",
      decorator: [
        "purchaseOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency_placeholder",
      decorator: [
        "currency",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    bankName: {
      label: "lbl_bank_name",
      name: "bankName",
      placeholder: "lbl_bank_name_placeholder",
      decorator: [
        "bankName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    giroNumber: {
      label: "lbl_giro_number",
      name: "giroNumber",
      placeholder: "lbl_giro_number_placeholder",
      decorator: [
        "giroNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "lbl_status_placeholder",
      decorator: ["status"],
    },
    apPaymentType: {
      label: "lbl_ap_payment_type",
      name: "apPaymentType",
      placeholder: "lbl_ap_payment_type_placeholder",
      decorator: ["apPaymentType"],
    },
  };

  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.handleFind(false);
  }

  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.handleFind(false);
  }

  checkValue(value): string {
    if (value && !value.includes("undefined")) {
      return value;
    } else {
      return ",ALL";
    }
  }

  assignSearch(key, value, and): string {
    const listParamsSearch = {
      branch: `branch.secureId~${value}`,
      apPaymentNumber: `documentNo~${value}`,
      supplierName: `supplier.secureId~*${value}*`,
      currency: `currency.currencyCode~${value}`,
      giroNumber: `chequeNumber~*${value}*`,
      status: `status~*${value}*`,
      apPaymentType: `paymentType~*${value}*`,
      bankName: `companyBank.secureId~*${value}*`,
    };
    if (
      key === "apPaymentDate" &&
      value &&
      Array.isArray(value) &&
      value.length > 0
    ) {
      return (
        and +
        `paymentDate>=${moment(value[0])
          .set({ hour: 0, minute: 0, second: 0 })
          .utcOffset("+07")
          .format()}_AND_paymentDate<=${moment(value[1])
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    } else if (value) {
      return and + listParamsSearch[key];
    } else {
      return "";
    }
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  handleCreate() {
    this.$router.push("/account-payables/ap-payments/create");
  }

  handleFind(firstSearch) {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        page: this.page - 1,
        limit: this.limit,
        sorts: "createdDate:desc",
      };
      if (firstSearch) params.page = 0;
      params.search = this.dynamicSearch(res);

      this.loading.loadingFind = true;
      apPaymentServices
        .listApPayment(params)
        .then(response => {
          this.totalData = response.totalElements;
          this.dataSource = response.data.map((dataMap, index) => {
            return {
              ...dataMap,
              key: index,
              paymentDate: moment(dataMap.paymentDate)
                .utcOffset("+07")
                .format(DEFAULT_DATE_FORMAT),
            };
          });
        })
        .finally(() => (this.loading.loadingFind = false));
    });
  }

  responseViewTable(response) {
    const { status, id } = response.data;
    if (ALLOW_EDIT.includes(status)) {
      this.$router.push(`/account-payables/ap-payments/edit/${id}`);
    } else {
      this.$router.push(`/account-payables/ap-payments/view/${id}`);
    }
  }

  async handleClickColumn(record, objColumnNameValue) {
    let data;
    const paramsJournal = {
      page: 0,
      limit: 10,
      search: `name~${record.journalNo}`,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (objColumnNameValue.column === "journalNo") {
      data = await generalJournalServices.listGeneralJournal(paramsJournal);
      if (data.data.length > 0) {
        this.$confirm({
          title: "You will be redirected to another page, are you sure?",
          onOk: () => {
            this.$router.push(
              `/generaljournal/journal/detail/${data.data[0].id}`
            );
          },
          onCancel() {
            return;
          },
        });
      }
    }
  }

  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.loadingBranch = false));
  }

  getApPaymentNumber(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) params.search = `documentNo~*${valueSearch}*`;

    this.loading.loadingApPaymentNumber = true;
    apPaymentServices
      .listApPayment(params)
      .then(response => {
        this.dataApPaymentNumber = response.data;
      })
      .finally(() => (this.loading.loadingApPaymentNumber = false));
  }

  getSupplierName(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `supplier~true_AND_active~true`,
    };
    if (valueSearch)
      params.search = `supplier~true_AND_active~true_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*_AND_supplier~true_AND_active~true`;
    this.loading.loadingSupplierName = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.dataSupplierName = res.data;
      })
      .finally(() => (this.loading.loadingSupplierName = false));
  }

  getCurrency(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) params.search = `currencyCode~${valueSearch}`;
    this.loading.loadingCurrency = true;
    settingsServices
      .listOfMasterCurrency(params, "")
      .then(response => (this.dataCurrency = response.data))
      .finally(() => (this.loading.loadingCurrency = false));
  }

  getBankName(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };

    if (valueSearch)
      params.search = `bankName~*${valueSearch}*_OR_bankAccNumber~*${valueSearch}*_OR_country~*${valueSearch}*`;

    this.loading.loadingBankName = true;
    masterServices
      .listCompanyBank(params)
      .then(data => {
        this.dataBankName = data.data;
      })
      .finally(() => (this.loading.loadingBankName = false));
  }

  getStatus(valueSearch) {
    const params = {
      page: 0,
      limit: 10,
      name: "INVOICE_AP_PAYMENT_STATUS",
    } as RequestQueryParamsModel;
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loading.loadingStatus = true;
    masterServices
      .listMaster(params)
      .then(res => {
        this.dataStatus = res;
      })
      .finally(() => (this.loading.loadingStatus = false));
  }

  getApPaymentType(valueSearch) {
    const params = {
      page: 0,
      limit: 10,
      name: "INVOICE_PAYMENT_TYPE",
    } as RequestQueryParamsModel;
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loading.loadingApPaymentType = true;
    masterServices
      .listMaster(params)
      .then(res => {
        this.dataApPaymentType = res;
      })
      .finally(() => (this.loading.loadingApPaymentType = false));
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  created() {
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getApPaymentNumber = debounceProcess(this.getApPaymentNumber, 400);
    this.getSupplierName = debounceProcess(this.getSupplierName, 400);
    this.getCurrency = debounceProcess(this.getCurrency, 400);
    this.getBankName = debounceProcess(this.getBankName, 400);
    this.getStatus = debounceProcess(this.getStatus, 400);
    this.getApPaymentType = debounceProcess(this.getApPaymentType, 400);
    this.getBranch("");
    this.getApPaymentNumber("");
    this.getSupplierName("");
    this.getCurrency("");
    this.getBankName("");
    this.getStatus("");
    this.getApPaymentType("");
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
